const middleware = {}

middleware['friendlyUrlCheckRedirect'] = require('../middleware/friendlyUrlCheckRedirect.ts')
middleware['friendlyUrlCheckRedirect'] = middleware['friendlyUrlCheckRedirect'].default || middleware['friendlyUrlCheckRedirect']

middleware['friendlyUrlExist'] = require('../middleware/friendlyUrlExist.ts')
middleware['friendlyUrlExist'] = middleware['friendlyUrlExist'].default || middleware['friendlyUrlExist']

middleware['htmlNotFoundRedirect'] = require('../middleware/htmlNotFoundRedirect.ts')
middleware['htmlNotFoundRedirect'] = middleware['htmlNotFoundRedirect'].default || middleware['htmlNotFoundRedirect']

middleware['lowercaseRedirect'] = require('../middleware/lowercaseRedirect.ts')
middleware['lowercaseRedirect'] = middleware['lowercaseRedirect'].default || middleware['lowercaseRedirect']

middleware['redirectUa'] = require('../middleware/redirectUa.ts')
middleware['redirectUa'] = middleware['redirectUa'].default || middleware['redirectUa']

middleware['reflect'] = require('../middleware/reflect.ts')
middleware['reflect'] = middleware['reflect'].default || middleware['reflect']

export default middleware
