import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _72a132da = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _31c6f2bd = () => interopDefault(import('../pages/brands/index.vue' /* webpackChunkName: "pages/brands/index" */))
const _5e24465a = () => interopDefault(import('../pages/cabinet.vue' /* webpackChunkName: "pages/cabinet" */))
const _57d5bd46 = () => interopDefault(import('../pages/cabinet/index.vue' /* webpackChunkName: "pages/cabinet/index" */))
const _5a1caa2a = () => interopDefault(import('../pages/cabinet/bonus.vue' /* webpackChunkName: "pages/cabinet/bonus" */))
const _9a034466 = () => interopDefault(import('../pages/cabinet/mailings.vue' /* webpackChunkName: "pages/cabinet/mailings" */))
const _9a700b2c = () => interopDefault(import('../pages/cabinet/orders.vue' /* webpackChunkName: "pages/cabinet/orders" */))
const _3e1503bc = () => interopDefault(import('../pages/cabinet/personal-data.vue' /* webpackChunkName: "pages/cabinet/personal-data" */))
const _644d2f66 = () => interopDefault(import('../pages/cabinet/reviews.vue' /* webpackChunkName: "pages/cabinet/reviews" */))
const _62f71379 = () => interopDefault(import('../pages/cabinet/watched.vue' /* webpackChunkName: "pages/cabinet/watched" */))
const _248db60a = () => interopDefault(import('../pages/cabinet/wishlist.vue' /* webpackChunkName: "pages/cabinet/wishlist" */))
const _92e6bf36 = () => interopDefault(import('../pages/cards.vue' /* webpackChunkName: "pages/cards" */))
const _8a9f0698 = () => interopDefault(import('../pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _55ec1267 = () => interopDefault(import('../pages/compare.vue' /* webpackChunkName: "pages/compare" */))
const _79d89540 = () => interopDefault(import('../pages/page/index.vue' /* webpackChunkName: "pages/page/index" */))
const _6924e235 = () => interopDefault(import('../pages/sale.vue' /* webpackChunkName: "pages/sale" */))
const _0fb19e98 = () => interopDefault(import('../pages/stock.vue' /* webpackChunkName: "pages/stock" */))
const _fa53ab92 = () => interopDefault(import('../pages/thanks.vue' /* webpackChunkName: "pages/thanks" */))
const _d80adb58 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _8c08859a = () => interopDefault(import('../pages/article/_slug.vue' /* webpackChunkName: "pages/article/_slug" */))
const _760e756a = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _30105175 = () => interopDefault(import('../pages/brands/_slug.vue' /* webpackChunkName: "pages/brands/_slug" */))
const _3b9e3e99 = () => interopDefault(import('../pages/catalog/_slug/index.vue' /* webpackChunkName: "pages/catalog/_slug/index" */))
const _803796b2 = () => interopDefault(import('../pages/category/_slug.vue' /* webpackChunkName: "pages/category/_slug" */))
const _7821f3f8 = () => interopDefault(import('../pages/page/_slug.vue' /* webpackChunkName: "pages/page/_slug" */))
const _faa04c28 = () => interopDefault(import('../pages/product/_slug.vue' /* webpackChunkName: "pages/product/_slug" */))
const _6216e322 = () => interopDefault(import('../pages/search/_phrase/index.vue' /* webpackChunkName: "pages/search/_phrase/index" */))
const _fe317dcc = () => interopDefault(import('../pages/tag/_slug/index.vue' /* webpackChunkName: "pages/tag/_slug/index" */))
const _8f3a2720 = () => interopDefault(import('../pages/catalog/_slug/f/index.vue' /* webpackChunkName: "pages/catalog/_slug/f/index" */))
const _234492b9 = () => interopDefault(import('../pages/search/_phrase/f/index.vue' /* webpackChunkName: "pages/search/_phrase/f/index" */))
const _61155a9e = () => interopDefault(import('../pages/tag/_slug/f/index.vue' /* webpackChunkName: "pages/tag/_slug/f/index" */))
const _11b9287d = () => interopDefault(import('../pages/catalog/_slug/f/_.vue' /* webpackChunkName: "pages/catalog/_slug/f/_" */))
const _5df35b46 = () => interopDefault(import('../pages/search/_phrase/f/_.vue' /* webpackChunkName: "pages/search/_phrase/f/_" */))
const _a29a5184 = () => interopDefault(import('../pages/tag/_slug/f/_.vue' /* webpackChunkName: "pages/tag/_slug/f/_" */))
const _dd94d268 = () => interopDefault(import('../pages/catalog/_slug/_friendly_slug/index.vue' /* webpackChunkName: "pages/catalog/_slug/_friendly_slug/index" */))
const _6cc21d20 = () => interopDefault(import('../pages/catalog/_slug/_friendly_slug/f/_.vue' /* webpackChunkName: "pages/catalog/_slug/_friendly_slug/f/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/blog/",
    component: _72a132da,
    pathToRegexpOptions: {"strict":true},
    name: "blog-default___ru"
  }, {
    path: "/brands/",
    component: _31c6f2bd,
    pathToRegexpOptions: {"strict":true},
    name: "brands___ru"
  }, {
    path: "/cabinet/",
    component: _5e24465a,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "",
      component: _57d5bd46,
      pathToRegexpOptions: {"strict":true},
      name: "cabinet___ru"
    }, {
      path: "bonus/",
      component: _5a1caa2a,
      pathToRegexpOptions: {"strict":true},
      name: "cabinet-bonus___ru"
    }, {
      path: "mailings/",
      component: _9a034466,
      pathToRegexpOptions: {"strict":true},
      name: "cabinet-mailings___ru"
    }, {
      path: "orders/",
      component: _9a700b2c,
      pathToRegexpOptions: {"strict":true},
      name: "cabinet-orders___ru"
    }, {
      path: "personal-data/",
      component: _3e1503bc,
      pathToRegexpOptions: {"strict":true},
      name: "cabinet-personal-data___ru"
    }, {
      path: "reviews/",
      component: _644d2f66,
      pathToRegexpOptions: {"strict":true},
      name: "cabinet-reviews___ru"
    }, {
      path: "watched/",
      component: _62f71379,
      pathToRegexpOptions: {"strict":true},
      name: "watched___ru"
    }, {
      path: "wishlist/",
      component: _248db60a,
      pathToRegexpOptions: {"strict":true},
      name: "wishlist___ru"
    }]
  }, {
    path: "/cards/",
    component: _92e6bf36,
    pathToRegexpOptions: {"strict":true},
    name: "cards___ru"
  }, {
    path: "/checkout/",
    component: _8a9f0698,
    pathToRegexpOptions: {"strict":true},
    name: "checkout___ru"
  }, {
    path: "/compare/",
    component: _55ec1267,
    pathToRegexpOptions: {"strict":true},
    name: "compare___ru"
  }, {
    path: "/page/",
    component: _79d89540,
    pathToRegexpOptions: {"strict":true},
    name: "page___ru"
  }, {
    path: "/sale/",
    component: _6924e235,
    pathToRegexpOptions: {"strict":true},
    name: "sale___ru"
  }, {
    path: "/stock/",
    component: _0fb19e98,
    pathToRegexpOptions: {"strict":true},
    name: "stocks___ru"
  }, {
    path: "/thanks/",
    component: _fa53ab92,
    pathToRegexpOptions: {"strict":true},
    name: "thanks___ru"
  }, {
    path: "/ua/",
    component: _d80adb58,
    pathToRegexpOptions: {"strict":true},
    name: "index___ua"
  }, {
    path: "/ua/blog/",
    component: _72a132da,
    pathToRegexpOptions: {"strict":true},
    name: "blog-default___ua"
  }, {
    path: "/ua/brands/",
    component: _31c6f2bd,
    pathToRegexpOptions: {"strict":true},
    name: "brands___ua"
  }, {
    path: "/ua/cabinet/",
    component: _5e24465a,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "",
      component: _57d5bd46,
      pathToRegexpOptions: {"strict":true},
      name: "cabinet___ua"
    }, {
      path: "bonus/",
      component: _5a1caa2a,
      pathToRegexpOptions: {"strict":true},
      name: "cabinet-bonus___ua"
    }, {
      path: "mailings/",
      component: _9a034466,
      pathToRegexpOptions: {"strict":true},
      name: "cabinet-mailings___ua"
    }, {
      path: "orders/",
      component: _9a700b2c,
      pathToRegexpOptions: {"strict":true},
      name: "cabinet-orders___ua"
    }, {
      path: "personal-data/",
      component: _3e1503bc,
      pathToRegexpOptions: {"strict":true},
      name: "cabinet-personal-data___ua"
    }, {
      path: "reviews/",
      component: _644d2f66,
      pathToRegexpOptions: {"strict":true},
      name: "cabinet-reviews___ua"
    }, {
      path: "watched/",
      component: _62f71379,
      pathToRegexpOptions: {"strict":true},
      name: "watched___ua"
    }, {
      path: "wishlist/",
      component: _248db60a,
      pathToRegexpOptions: {"strict":true},
      name: "wishlist___ua"
    }]
  }, {
    path: "/ua/cards/",
    component: _92e6bf36,
    pathToRegexpOptions: {"strict":true},
    name: "cards___ua"
  }, {
    path: "/ua/checkout/",
    component: _8a9f0698,
    pathToRegexpOptions: {"strict":true},
    name: "checkout___ua"
  }, {
    path: "/ua/compare/",
    component: _55ec1267,
    pathToRegexpOptions: {"strict":true},
    name: "compare___ua"
  }, {
    path: "/ua/page/",
    component: _79d89540,
    pathToRegexpOptions: {"strict":true},
    name: "page___ua"
  }, {
    path: "/ua/sale/",
    component: _6924e235,
    pathToRegexpOptions: {"strict":true},
    name: "sale___ua"
  }, {
    path: "/ua/stock/",
    component: _0fb19e98,
    pathToRegexpOptions: {"strict":true},
    name: "stocks___ua"
  }, {
    path: "/ua/thanks/",
    component: _fa53ab92,
    pathToRegexpOptions: {"strict":true},
    name: "thanks___ua"
  }, {
    path: "/ua/article/:slug?/",
    component: _8c08859a,
    pathToRegexpOptions: {"strict":true},
    name: "article___ua"
  }, {
    path: "/ua/blog/:slug/",
    component: _760e756a,
    pathToRegexpOptions: {"strict":true},
    name: "blog___ua"
  }, {
    path: "/ua/brands/:slug/",
    component: _30105175,
    pathToRegexpOptions: {"strict":true},
    name: "brand___ua"
  }, {
    path: "/ua/catalog/:slug?/",
    component: _3b9e3e99,
    pathToRegexpOptions: {"strict":true},
    name: "catalog___ua"
  }, {
    path: "/ua/category/:slug?/",
    component: _803796b2,
    pathToRegexpOptions: {"strict":true},
    name: "category-slug___ua"
  }, {
    path: "/ua/page/:slug/",
    component: _7821f3f8,
    pathToRegexpOptions: {"strict":true},
    name: "page-slug___ua"
  }, {
    path: "/ua/product/:slug?/",
    component: _faa04c28,
    pathToRegexpOptions: {"strict":true},
    name: "product___ua"
  }, {
    path: "/ua/search/:phrase?/",
    component: _6216e322,
    pathToRegexpOptions: {"strict":true},
    name: "search___ua"
  }, {
    path: "/ua/tag/:slug?/",
    component: _fe317dcc,
    pathToRegexpOptions: {"strict":true},
    name: "tag___ua"
  }, {
    path: "/ua/catalog/:slug?/f/",
    component: _8f3a2720,
    pathToRegexpOptions: {"strict":true},
    name: "catalog-slug-f___ua"
  }, {
    path: "/ua/search/:phrase?/f/",
    component: _234492b9,
    pathToRegexpOptions: {"strict":true},
    name: "search-phrase-f___ua"
  }, {
    path: "/ua/tag/:slug?/f/",
    component: _61155a9e,
    pathToRegexpOptions: {"strict":true},
    name: "tag-slug-f___ua"
  }, {
    path: "/ua/catalog/:slug?/f/*/",
    component: _11b9287d,
    pathToRegexpOptions: {"strict":true},
    name: "catalog-filter___ua"
  }, {
    path: "/ua/search/:phrase?/f/*/",
    component: _5df35b46,
    pathToRegexpOptions: {"strict":true},
    name: "search-phrase-f-all___ua"
  }, {
    path: "/ua/tag/:slug?/f/*/",
    component: _a29a5184,
    pathToRegexpOptions: {"strict":true},
    name: "tag-slug-f-all___ua"
  }, {
    path: "/ua/catalog/:slug?/:friendly_slug/",
    component: _dd94d268,
    pathToRegexpOptions: {"strict":true},
    name: "catalog-friendly___ua"
  }, {
    path: "/ua/catalog/:slug?/:friendly_slug/f/*/",
    component: _6cc21d20,
    pathToRegexpOptions: {"strict":true},
    name: "catalog-friendly-filter___ua"
  }, {
    path: "/article/:slug?/",
    component: _8c08859a,
    pathToRegexpOptions: {"strict":true},
    name: "article___ru"
  }, {
    path: "/blog/:slug/",
    component: _760e756a,
    pathToRegexpOptions: {"strict":true},
    name: "blog___ru"
  }, {
    path: "/brands/:slug/",
    component: _30105175,
    pathToRegexpOptions: {"strict":true},
    name: "brand___ru"
  }, {
    path: "/catalog/:slug?/",
    component: _3b9e3e99,
    pathToRegexpOptions: {"strict":true},
    name: "catalog___ru"
  }, {
    path: "/category/:slug?/",
    component: _803796b2,
    pathToRegexpOptions: {"strict":true},
    name: "category-slug___ru"
  }, {
    path: "/page/:slug/",
    component: _7821f3f8,
    pathToRegexpOptions: {"strict":true},
    name: "page-slug___ru"
  }, {
    path: "/product/:slug?/",
    component: _faa04c28,
    pathToRegexpOptions: {"strict":true},
    name: "product___ru"
  }, {
    path: "/search/:phrase?/",
    component: _6216e322,
    pathToRegexpOptions: {"strict":true},
    name: "search___ru"
  }, {
    path: "/tag/:slug?/",
    component: _fe317dcc,
    pathToRegexpOptions: {"strict":true},
    name: "tag___ru"
  }, {
    path: "/catalog/:slug?/f/",
    component: _8f3a2720,
    pathToRegexpOptions: {"strict":true},
    name: "catalog-slug-f___ru"
  }, {
    path: "/search/:phrase?/f/",
    component: _234492b9,
    pathToRegexpOptions: {"strict":true},
    name: "search-phrase-f___ru"
  }, {
    path: "/tag/:slug?/f/",
    component: _61155a9e,
    pathToRegexpOptions: {"strict":true},
    name: "tag-slug-f___ru"
  }, {
    path: "/catalog/:slug?/f/*/",
    component: _11b9287d,
    pathToRegexpOptions: {"strict":true},
    name: "catalog-filter___ru"
  }, {
    path: "/search/:phrase?/f/*/",
    component: _5df35b46,
    pathToRegexpOptions: {"strict":true},
    name: "search-phrase-f-all___ru"
  }, {
    path: "/tag/:slug?/f/*/",
    component: _a29a5184,
    pathToRegexpOptions: {"strict":true},
    name: "tag-slug-f-all___ru"
  }, {
    path: "/catalog/:slug?/:friendly_slug/",
    component: _dd94d268,
    pathToRegexpOptions: {"strict":true},
    name: "catalog-friendly___ru"
  }, {
    path: "/catalog/:slug?/:friendly_slug/f/*/",
    component: _6cc21d20,
    pathToRegexpOptions: {"strict":true},
    name: "catalog-friendly-filter___ru"
  }, {
    path: "/",
    component: _d80adb58,
    pathToRegexpOptions: {"strict":true},
    name: "index___ru"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
