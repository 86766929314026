import locale781e0eeb from '../../lang/ua.ts'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: true,
}
export const options = {
  vueI18n: {"fallbackLocale":"ua"},
  vueI18nLoader: false,
  locales: [{"iso":"ru-UA","code":"ru","file":"ru.ts"},{"iso":"uk-UA","code":"ua","file":"ua.ts"}],
  defaultLocale: "ru",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "lang/",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieAge":365,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true},
  differentDomains: false,
  baseUrl: "https://zoo.com.ua",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  seo: true,
  normalizedLocales: [{"iso":"ru-UA","code":"ru","file":"ru.ts"},{"iso":"uk-UA","code":"ua","file":"ua.ts"}],
  localeCodes: ["ru","ua"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "r",
  3: "u",
  4: ".",
  5: "t",
  6: "s",
  7: "\"",
  8: ":",
  9: "\"",
  10: ".",
  11: ".",
  12: "/",
  13: "l",
  14: "a",
  15: "n",
  16: "g",
  17: "/",
  18: "r",
  19: "u",
  20: ".",
  21: "t",
  22: "s",
  23: "\"",
  24: ",",
  25: "\"",
  26: "u",
  27: "a",
  28: ".",
  29: "t",
  30: "s",
  31: "\"",
  32: ":",
  33: "\"",
  34: ".",
  35: ".",
  36: "/",
  37: "l",
  38: "a",
  39: "n",
  40: "g",
  41: "/",
  42: "u",
  43: "a",
  44: ".",
  45: "t",
  46: "s",
  47: "\"",
  48: "}",
}

export const localeMessages = {
  'ua.ts': () => Promise.resolve(locale781e0eeb),
  'ru.ts': () => import('../../lang/ru.ts' /* webpackChunkName: "lang-ru.ts" */),
}
